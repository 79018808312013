<script>
export default {
    name: "rokman-modal",

    data() {
        return {
            showModal: false,

            classes: {
                overlay: 'z-50 bg-black bg-opacity-50',
                wrapper: 'z-50 max-w-2xl px-3 py-12',
                modal: 'bg-white shadow rounded',
                body: 'p-3 text-left',
                header: 'border-b border-gray-100 p-3 rounded-t',
                footer: 'bg-gray-100 p-3 rounded-b',
                close: 'bg-gray text-black rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out hover:bg-gold hover:text-white focus:ring-2 focus:ring-gold focus:outline-none focus:ring-opacity-50',
                closeIcon: 'fill-current h-4 w-4',
                overlayEnterClass: 'opacity-0',
                overlayEnterActiveClass: 'transition ease-out duration-100',
                overlayEnterToClass: 'opacity-100',
                overlayLeaveClass: 'opacity-100',
                overlayLeaveActiveClass: 'transition ease-in duration-75',
                overlayLeaveToClass: 'opacity-0',
                enterClass: '',
                enterActiveClass: '',
                enterToClass: '',
                leaveClass: '',
                leaveActiveClass: '',
                leaveToClass: '',
            },
        };
    },

    methods: {
        openModal() {
            this.showModal = !this.showModal;
        }
    },
}
</script>
