<template>
    <div class="tooltip-box cursor-pointer">
        <slot/>
        <div :class="'tooltip' + (position ? ' ' + position : '')">
            <span class="text">{{ text }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "tooltip",

    props: {
        text: {
            type: String,
            required: true
        },

        position: {
            type: String,
            required: false
        }
    }
};
</script>

<style scoped>
.tooltip-box {
    position: relative;
    display: inline-block;
}

.tooltip-box:hover .tooltip {
    display: inline-block;
}

.tooltip {
    color: #ffffff;
    text-align: center;
    padding: 5px;
    border-radius: 2px;

    width: 255px;
    bottom: 115%;
    left: 50%;
    margin-left: -125px;

    display: none;
    transition: opacity 1s;

    position: absolute;
    z-index: 1;

    background: #bfa049;
}

.tooltip.left {
    bottom: 0;
    left: -16.2rem;
    margin-left: 0;
}

.text::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #bfa049 transparent transparent transparent;
}

.tooltip.left .text::after {
    top: 50%;
    left: 100%;
    margin-right: -5px;
    margin-left: unset;
    border-color: transparent transparent transparent #bfa049;
}
</style>
