<template>
    <div class="grid grid-cols-2 text-center">
        <!-- View submission in original admin dashboard -->
        <a target="_blank" :href="'/admin/submissions/view/' + submissionId" class="hover:pointer mx-auto">
            <font-awesome-icon icon="fa-regular fa-eye"/>
        </a>

        <!-- If not verified show accept icon -->
        <button v-if="!submission.verified" @click="acceptSubmission()" class="hover:pointer btn-link mx-auto">
            <font-awesome-icon icon="fa-regular fa-check-circle"/>
        </button>

        <rokman-modal v-else inline-template>
            <div>
                <t-modal v-model="showModal" :classes="classes">
                    <template v-slot:header>
                        <div class="flex justify-between">
                            <h2>Decline Submission Entry</h2>
                        </div>
                    </template>

                    <div class="my-5 grid grid-cols-1 md:grid-cols-3">
                        <div class="px-2 mb-5 md:mb-0">
                            <h2 class="underline"><b>User</b></h2>
                            {{ $parent.submission.name}}<br>
                            {{ $parent.submission.email}}
                        </div>
                    </div>

                    <div class="my-5 grid grid-cols-1">
                        <div class="px-2 mb-5 md:mb-0">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="reason">Reason</label>

                            <select name="reason" required v-model="$parent.form.reason"
                                    class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                                    id="reason">
                                <option value="" disabled>Please Select</option>
                                <option value="1">Broken/not accessible evidence link</option>
                                <option value="2">Other</option>
                            </select>
                        </div>
                    </div>

                    <div class="my-5 grid grid-cols-1">
                        <div class="px-2 mb-5 md:mb-0">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="details">More Details</label>

                            <textarea name="details" rows="10" v-model="$parent.form.details"
                                      class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                                      id="details"></textarea>
                        </div>
                    </div>

                    <div class="flex justify-center md:justify-end my-5">
                        <div @click="$parent.submitForm()"
                                class='inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150'>
                            Submit
                        </div>
                    </div>
                </t-modal>

                <button class="btn-link mx-auto" @click="openModal()">
                    <font-awesome-icon icon="fa-regular fa-times-circle"/>
                </button>
            </div>
        </rokman-modal>
    </div>
</template>

<script>
import rokmanModal from "../rokman-modal.vue";

export default {
    name: "actions",

    components: {
        rokmanModal
    },

    props: {
        submissionId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            submission: {
                verified: 1,
                name: '',
                email: ''
            },

            form: {
                reason: '',
                details: '',
            }
        }
    },

    methods: {
        /**
         * Load the submission
         */
        loadSubmission() {
            const self = this;

            axios
                .get('/admin/submissions/load/' + this.submissionId)
                .then( (response) => {
                    self.submission = response.data;
                })
                .catch(function (error) {
                    let message;
                    if (error.response) {
                        message = error.response.data;
                    } else if (error.request) {
                        message = error.request;
                    } else {
                        message = error.message;
                    }

                    alert('Error: ' + message);
                });
        },

        /**
         * Accept submission
         */
        acceptSubmission() {
            const self = this;

            axios
                .post('/admin/submissions/accept/' + this.submissionId)
                .then( (response) => {
                    self.submission.verified = 1;
                })
                .catch(function (error) {
                    let message;
                    if (error.response) {
                        message = error.response.data;
                    } else if (error.request) {
                        message = error.request;
                    } else {
                        message = error.message;
                    }

                    alert('Error: ' + message);
                });
        },

        /**
         * Submit decline submission form
         */
        submitForm() {
            const self = this;

            axios
                .post('/admin/submissions/decline/' + this.submissionId, self.form)
                .then( (response) => {
                    self.submission.verified = 0;
                })
                .catch(function (error) {
                    let message;
                    if (error.response) {
                        message = error.response.data;
                    } else if (error.request) {
                        message = error.request;
                    } else {
                        message = error.message;
                    }

                    alert('Error: ' + message);
                });
        }
    },

    mounted() {
        this.loadSubmission();
    }
}
</script>

<style scoped>

</style>
