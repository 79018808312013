<script>
import CoolLightBox from 'vue-cool-lightbox'
import Tooltip from "../includes/tooltip.vue";
import RokmanModal from "../admin/rokman-modal.vue";

export default {
    name: "submission-entry",

    components: {
        CoolLightBox,
        Tooltip,
        RokmanModal
    },

    props: {
        submissionProp: {
            type: Object,
            required: true,
            default: {}
        }
    },

    data() {
        return {
            submission: this.submissionProp,
            lightBoxIndex: null,
        }
    },

    computed: {
        lightBoxMedia() {
            return this.submission?.evidence?.map((evidence) => {
                return evidence.url;
            })
        },

        submissionFeedImages() {
            return this.submission?.evidence?.filter((evidence, index) => {
                return index < 3;
            })
        },

        hasMoreThan3Evidence() {
            return this.submission?.evidence?.length > 3;
        }
    },

    methods: {
        submitForm() {
            const self = this;
            axios
                .post('/portal/submission/flag/' + self.submission.id)
                .then((response) => {
                    self.submission.flagged_for_review = 1;
                });
        }
    },

    watch: {},

    mounted() {
    },

    beforeDestroy() {
    },
}
</script>

<template>
    <div class="flex flex-col my-5 border-b py-5 border-gray border:opacity-25">
        <div class="flex gap-3">
            <div id="avatar" class="mb-4">
                <img v-if="submission?.avatar"
                     :src="submission?.avatar"
                     :alt="submission?.name + ' Avatar'"
                     class="w-12 h-12 rounded-full mx-auto"
                >
                <div v-else class="relative w-12 h-12 overflow-hidden bg-gold rounded-full dark:bg-gray-600 mx-auto">
                    <div class="top-2.5 absolute w-12 h-6 text-center text-white">
                        <span class="text-lg">{{ submission?.initials }}</span>
                    </div>
                </div>
            </div>

            <div>
                <div class="flex gap-1 items-center">
                    <h3 class="font-bold">{{ submission?.name }}</h3>
                    <p class="italic text-sm text-gold">{{ submission?.created_at }}</p>
                </div>
                <div>
                    <p>{{ submission?.series }} / {{ submission?.challenge }}</p>
                </div>
            </div>
        </div>

        <div class="flex flex-col gap-1 mb-3">
            <p v-for="(detail, index) in submission?.details" :key="index">
                {{ detail }}
            </p>
        </div>

        <CoolLightBox
            :items="lightBoxMedia"
            :index="lightBoxIndex"
            :effect="'fade'"
            @close="lightBoxIndex = null"
        />

        <div class="gallery">
            <figure
                v-for="(image, imageIndex) in submissionFeedImages"
                :key="imageIndex"
                :class="'gallery_item gallery_item_' + (imageIndex + 1)"
                @click="lightBoxIndex = imageIndex"
            >
                <img
                    :src="image?.thumbnail || image?.url"
                    class="gallery_img"
                    :alt="'Image ' + (imageIndex + 1)"
                />

                <div class="gallery_item_overlay" v-if="imageIndex !== 2 || !hasMoreThan3Evidence">
                    <div class="gallery_item_overlay_caption">
                        {{ (image?.type === 'video') ? 'Watch Video' : 'View Image' }}
                    </div>
                </div>

                <div class="gallery_item_overlay more_evidence" v-if="imageIndex === 2 && hasMoreThan3Evidence">
                    <div class="gallery_item_overlay_caption">
                        + {{ submission?.evidence?.length - 3 }}
                    </div>
                </div>
            </figure>
        </div>

        <div class="flex justify-center md:justify-end mt-3 md:mt-1">
            <div v-if="!submission?.verified_by_admin">
                <Tooltip v-if="!submission?.flagged_for_review" text="Flag submission for admin review">
                    <rokman-modal inline-template>
                        <div>
                            <t-modal v-model="showModal" :classes="classes">
                                <template v-slot:header>
                                    <div class="flex justify-between">
                                        <h2>Flag for review</h2>
                                    </div>
                                </template>

                                <div class="my-5">
                                    <div class="px-2 mb-5 md:mb-0">
                                        Are you sure you want to flag this submission for admin review?
                                    </div>
                                </div>

                                <div class="flex justify-center md:justify-end my-5">
                                    <div @click="$parent.$parent.submitForm()"
                                         class='inline-flex justify-center text-sm items-center text-center px-4 py-2 bg-new-green text-black rounded-2xl font-semibold uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer'>
                                        Send for review
                                    </div>
                                </div>
                            </t-modal>

                            <i class="fa-sharp fa-regular fa-flag" @click="openModal()"></i>
                        </div>
                    </rokman-modal>
                </Tooltip>

                <Tooltip v-else text="Submission is currently pending admin review">
                    <i class="fa-sharp fa-solid fa-flag"></i>
                </Tooltip>
            </div>

            <div v-else>
                <Tooltip text="Submission has been reviewed and verified">
                    <i class="fa-sharp fa-regular fa-badge-check"></i>
                </Tooltip>
            </div>
        </div>
    </div>
</template>

<style scoped>
.gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 5vw);
    grid-gap: 0.5rem;
}

@media screen and (max-width: 768px) {
    .gallery {
        grid-template-columns: repeat(6, 2fr);
        grid-template-rows: repeat(6, 11vw);
    }
}

.gallery_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery_item {
    position: relative;
    overflow: hidden;
}

.gallery_item_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(191, 160, 73, 1);
}

.gallery_item .gallery_item_overlay.more_evidence {
    opacity: 0.55;
}

.gallery_item:hover .gallery_item_overlay {
    opacity: 0.9;
}

.gallery_item_overlay_caption {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.gallery_item:hover {
    cursor: pointer;
}

.gallery_item_1 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 7;
}

.gallery_item_2 {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 4;
}

.gallery_item_3 {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 7;
}
</style>
