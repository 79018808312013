<template>
    <div v-if="errorsProp.length" class="mb-4 w-full border-2 p-4 border-gold">
        <div class="font-normal">
            Whoops! There seems to be an issue.
        </div>

        <ul class="mt-3 list-disc list-inside text-sm font-normal">
            <li v-for="(error, index) in errorsProp" :key="index">{{ error }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "error-message",

    props: {
        errorsProp: {
            type: Array,
            required: false,
            default: [],
        }
    },

    data() {
        return {}
    },

    watch: {}
}
</script>

<style scoped>

</style>
