<template>
    <div>
        <success-message :success-message-prop="successMessage"></success-message>
        <error-message :errors-prop="errors"></error-message>

        <div v-if="points.active">
            <div class="my-5 grid grid-cols-1 md:grid-cols-2">
                <div class="px-2 mb-5 mb-0">
                    <label for="point_type"
                           class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Point Type
                    </label>

                    <select name="point_type"
                            v-model="points.point_type"
                            class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                            id="point_type">
                        <option value="null" disabled selected>Please Select...</option>
                        <option value="single" selected>Single</option>
                        <option value="tiered">Tiered</option>
                    </select>
                </div>

                <div class="px-2 mb-5 mb-0">
                    <label for="point_type"
                           class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Targeted?
                    </label>

                    <select name="point_type"
                            v-model="points.targeted"
                            class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                            id="point_type">
                        <option value="null" disabled selected>Please Select...</option>
                        <option value="0" selected>No</option>
                        <option value="1">Yes</option>
                    </select>
                </div>
            </div>

            <!-- Single Points-->
            <div v-if="points.point_type === 'single'" class="my-5 grid grid-cols-1 md:grid-cols-3">
                <div  class="px-2 mb-5 mb-0">
                    <label for="max_points"
                           class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Points
                    </label>

                    <input id="max_points" type="number" name="max_points" required="required" placeholder="Points"
                           v-model="points.max_points"
                           class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                </div>

                <div v-if="points.targeted == 1" class="px-2 mb-5 md:mb-0">
                    <label for="target" class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Target
                    </label>

                    <input id="target" :type="targetType" name="target" required="required" placeholder="Target"
                           v-model="points.target" step="1"
                           class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                </div>

                <div v-if="points.targeted == 1" class="px-2 mb-5 md:mb-0">
                    <label for="operator" class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Operator
                    </label>

                    <select name="operator"
                            v-model="points.operator"
                            class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                            id="operator">
                        <option value="null" disabled selected>Please Select...</option>
                        <option v-for="(operator, index) in operators" :key="index" :value="operator">{{ operator }}
                        </option>
                    </select>
                </div>
            </div>

            <!-- Tiered Points -->
            <div v-if="points.point_type === 'tiered'">
                <div class="grid grid-cols-2">
                    <p class="text-black font-bold mb-2">
                        Tiers
                    </p>
                </div>

                <div v-for="(tier, index) in points.tiers" :key="index"
                     class="my-5 grid grid-cols-1 md:grid-cols-2 "
                    :class="points.targeted == 1 ? 'lg:grid-cols-4' : ''"
                >
                    <div class="px-2 mb-5 md:mb-0">
                        <label :for="'tier_' + index + '_entry_level'"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Level
                        </label>

                        <input :id="'tier_' + index + '_entry_level'" type="text"
                               :name="'tier_' + index + '_entry_level'"
                               required="required"
                               placeholder="Entry Level"
                               disabled
                               v-model="points.tiers[index].entry_level"
                               class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                    </div>

                    <div v-if="points.targeted == 1" class="px-2 mb-5 md:mb-0">
                        <label :for="'tier_' + index + '_target'"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Target
                        </label>

                        <input id="target" :type="targetType" :name="'tier_' + index + '_target'" required="required" placeholder="Target"
                               v-model="points.tiers[index].target" step="1"
                               class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                    </div>

                    <div v-if="points.targeted == 1" class="px-2 mb-5 md:mb-0">
                        <label :for="'tier_' + index + '_operator'"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Operator
                        </label>

                        <select :name="'tier_' + index + '_operator'"
                                v-model="points.tiers[index].operator"
                                class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                                id="operator">
                            <option value="null" disabled selected>Please Select...</option>
                            <option v-for="(operator, index) in operators" :key="index" :value="operator">
                                {{ operator }}
                            </option>
                        </select>
                    </div>

                    <div class="px-2 mb-5 md:mb-0">
                        <label :for="'tier_' + index + '_points'"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Points
                        </label>

                        <input :id="'tier_' + index + '_points'" type="number" :name="'tier_' + index + '_points'"
                               required="required" placeholder="Points"
                               v-model="points.tiers[index].points"
                               class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SuccessMessage from "../includes/success-message";
import ErrorMessage from "../includes/error-message";

export default {
    name: "completion-points",

    components: {
        SuccessMessage,
        ErrorMessage,
    },

    props: {
        pointsProp: {
            type: Object,
            required: true,
            default: {
                'max_points': null,
                'point_type': 'single',
                'targeted': 1,
                'target': null,
                'operator': null,
                'tiers': [],
            },
        },
        challengeTypeProp: {
            type: String,
            required: false,
            default: '',
        }
    },

    data() {
        return {
            successMessage: null,
            errors: [],
            points: this.pointsProp,
            challengeType: this.challengeTypeProp,

            operators: [
                '<',
                '<=',
                '>',
                '>=',
            ],
        };
    },

    methods: {},

    computed: {
        /**
         * @returns {Document.point_type|string|*}
         */
        pointType() {
            return this.points.point_type;
        },

        /**
         * @returns {string}
         */
        targetType() {
            switch (this.challengeType) {
                case 'time':
                    return 'time';
                default:
                    return 'number';
            }
        }
    },

    watch: {
        pointType() {
            if (this.pointType === 'tiered') {
                this.points.tiers = [
                    {'entry_level_id': 1, 'entry_level': 'Advanced', 'points': 40, 'target': null, 'operator': null},
                    {
                        'entry_level_id': 2,
                        'entry_level': 'Intermediate',
                        'points': 30,
                        'target': null,
                        'operator': null
                    },
                    {'entry_level_id': 3, 'entry_level': 'Moderate', 'points': 20, 'target': null, 'operator': null},
                    {'entry_level_id': 4, 'entry_level': 'Novice', 'points': 10, 'target': null, 'operator': null},
                ];
            } else {
                this.points.tiers = [];
            }
        }
    }
}
</script>

<style scoped>

</style>
