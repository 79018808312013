import Vue from 'vue';
import VueTailwind from 'vue-tailwind/dist/full'
import FileUploader from 'laravel-file-uploader';
import vueFilePond from "vue-filepond";
import moment from "moment";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

Vue.use(VueTailwind);

/* Font Awesome */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEye, faEyeSlash, faCheckCircle, faTimesCircle, faDownload} from '@fortawesome/pro-regular-svg-icons'
library.add(faEye, faEyeSlash, faCheckCircle, faTimesCircle, faDownload)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginFilePoster,
    FilePondPluginImagePreview
);

window.moment = moment;
window.Vue = Vue;

require('./bootstrap');
require('alpinejs');

// JS Files

// Vue Files
import EvidenceUpload from "./components/evidence-upload";
import RokmanModal from "./components/admin/rokman-modal";
import PointsConfiguration from "./components/admin/points-configuration";
import Leaderboard from "./components/leaderboard";
import Loader from "./components/includes/loader.vue";
import SubmissionEntry from "./components/portal/submission.vue";
import Tooltip from "./components/includes/tooltip.vue";

if (document.getElementById('vue-component')) {
    const app = new Vue({
        el: '#vue-component',
        components: {
            FileUploader,
            FilePond,
            EvidenceUpload,
            RokmanModal,
            PointsConfiguration,
            Loader,
            Leaderboard,
            SubmissionEntry,
            Tooltip
        }
    });
}
