<template>
    <div>
        <p v-for="(evidence, index) in submissionEvidence">
            <template v-if="evidence !== null">
                <span class="capitalize">{{ index.replace('_', ' ') }}</span> <br> <span v-html="evidence"></span>
            </template>
        </p>
    </div>
</template>

<script>
import rokmanModal from "../rokman-modal.vue";
import Loader from "../../includes/loader.vue";

export default {
    name: "view-evidence",

    components: {
        Loader,
        rokmanModal
    },

    props: {
        submissionId: {
            type: Number,
            required: true
        },
        shouldTextBeGray: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            isLoading: true,
            submissionEvidence: []
        }
    },

    methods: {
        /**
         * Load the submission
         */
        loadSubmissionEvidence() {
            const self = this;

            axios
                .get('/admin/submissions/load/evidence/' + this.submissionId)
                .then( (response) => {
                    self.submissionEvidence = response.data;
                })
                .catch(function (error) {
                    let message;
                    if (error.response) {
                        message = error.response.data;
                    } else if (error.request) {
                        message = error.request;
                    } else {
                        message = error.message;
                    }

                    alert('Error: ' + message);
                });
        },
    },

    mounted() {
        this.loadSubmissionEvidence();
    }
}
</script>

<style scoped>

</style>
