import { render, staticRenderFns } from "./leaderboard.vue?vue&type=template&id=71c04129&scoped=true"
import script from "./leaderboard.vue?vue&type=script&lang=js"
export * from "./leaderboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c04129",
  null
  
)

export default component.exports