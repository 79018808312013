<template>
    <div>
        <success-message :success-message-prop="successMessage"></success-message>
        <error-message :errors-prop="errors"></error-message>

        <div v-if="customEntryLevelsProp">
            <div v-for="(entryLevel, index) in entryLevels" :key="index" v-if="!entryLevels[index].deleted"
                 class="my-5 grid grid-cols-1 md:grid-cols-4"
            >
                <div class="px-2 mb-5 md:mb-0 col-span-2">
                    <label :for="'entry_level_' + index"
                           class="text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Entry Level
                    </label>

                    <input :id="'entry_level_' + index" type="text"
                           :name="'entry_level_' + index"
                           required="required"
                           placeholder="Entry level"
                           v-model="entryLevels[index].name"
                           class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                </div>

                <div class="px-2 mb-5 md:mb-0">
                    <button class="mt-10 mr-5" @click="addEntryLevel()">
                        <i class="far fa-plus"></i>
                    </button>

                    <button v-if="entryLevels.length > 1" @click="deleteEntryLevel(index)">
                        <i class="far fa-minus"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ErrorMessage from "../includes/error-message.vue";
import SuccessMessage from "../includes/success-message.vue";

export default {
    name: "entry-levels",

    components: {
        SuccessMessage,
        ErrorMessage,
    },

    props: {
        customEntryLevelsProp: {
            type: Boolean,
            required: true,
            default: false,
        },
        entryLevelsProp: {
            type: Array,
            required: true,
            default: [],
        }
    },

    data() {
        return {
            successMessage: null,
            errors: [],

            entryLevels: this.entryLevelsProp
        }
    },

    methods: {
        addEntryLevel() {
            this.entryLevels.push({
                'id': null,
                'name': '',
                'deleted': false
            });
        },

        deleteEntryLevel(index) {
            this.entryLevels[index].deleted = true;
        },
    },

    watch: {}
}
</script>

<style scoped>

</style>
