<script>
export default {
    name: "evidence-upload",

    props: {
        primaryLinkProp: {
            default() {
                return '';
            }
        },
        fileUploadIdProp: {
            default() {
                return 6;
            }
        }
    },

    data() {
        return {
            primaryEvidenceType: 6,
            secondaryEvidenceType: 6,
            primaryLink: this.primaryLinkProp,
            supportingEvidence: false,
            filesUploaded: [],
            fileUploadId: this.fileUploadIdProp
        };
    },

    methods: {
        onFileUploaded(event) {
            this.filesUploaded.push({});
        },

        onFileDelete(event) {
            this.filesUploaded.shift();
        }
    },

    computed: {
        evidenceUploaded() {
            if (this.primaryEvidenceType == this.fileUploadId) {
                return (this.filesUploaded.length);
            }

            return (this.primaryLink.length > 0);
        },

        primaryLinkRequired() {
            return (this.primaryEvidenceType < this.fileUploadId);
        }
    },

    watch: {}
}
</script>
