<template>
    <div>
        <success-message :success-message-prop="successMessage"></success-message>
        <error-message :errors-prop="errors"></error-message>

        <div v-if="points.active">
            <div v-for="(pointConfig, index) in points.configs" :key="index">
                <div class="my-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                    <div class="px-2 my-5 md:mb-0">
                        <label for="point_type"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Bonus Point Type
                        </label>

                        <select name="point_type"
                                v-model="points.configs[index].bonus_point_type"
                                class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                                id="point_type">
                            <option value="null" disabled selected>Please Select...</option>
                            <option v-for="(bonusPointType, bonusPointTypeIndex) in bonusPointTypes"
                                    :key="bonusPointTypeIndex"
                                    :value="bonusPointType.name">
                                {{ bonusPointType.display }}
                            </option>
                        </select>
                    </div>

                    <div class="px-2 my-5 md:mb-0">
                        <label for="max_points"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Max Points
                        </label>

                        <input id="max_points" type="number" name="max_points" required="required"
                               placeholder="Max Points" :disabled="shouldDisableMaxPointsOption(index)"
                               v-model="points.configs[index].max_points"
                               class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                    </div>

                    <div class="px-2 my-5 md:mb-0">
                        <label for="point_type"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Point Type
                        </label>

                        <select name="point_type"
                                v-model="points.configs[index].point_type"
                                class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                                id="point_type">
                            <option value="null" disabled selected>Please Select...</option>
                            <option value="single" selected>Single</option>
                            <option value="tiered" :disabled="shouldDisableTieredOption(index)">Tiered</option>
                        </select>
                    </div>

                    <div class="px-2 my-5 md:mb-0">
                        <label for="entry-level"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Entry Level
                        </label>

                        <select name="entry-level"
                                v-model="points.configs[index].entry_level"
                                class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                                id="point_type">
                            <option value="null" disabled selected>Please Select...</option>
                            <option v-for="(entryLevel, entryLevelIndex) in entryLevels"
                                    :key="entryLevelIndex"
                                    :value="entryLevel.id"
                                    :disabled="shouldDisableEntryLevelOption(index) && entryLevel.id !== 0"
                            >
                                {{ entryLevel.display }}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Single Points-->
                <div v-if="points.configs[index].point_type === 'single'" class="my-5 grid grid-cols-1 md:grid-cols-2">
                    <div class="px-2 mb-5 md:mb-0">
                        <label for="target"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Target
                        </label>

                        <input v-if="!showTargetSelect(index)" id="target" :type="getTargetType(index)" name="target"
                               required="required" step="1" placeholder="Target"
                               v-model="points.configs[index].target"
                               :disabled="shouldDisableTargetOption(index)"
                               class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">

                        <select name="target" v-if="showTargetSelect(index)"
                                v-model="points.configs[index].target"
                                :disabled="shouldDisableTargetOption(index)"
                                class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                                id="target">
                            <option value="null" disabled selected>Please Select...</option>

                            <!-- Beat previous submission -->
                            <option
                                v-if="points.configs[index].bonus_point_type === 'beat_previous_submission' && bonusPointTypeIndex <= 5"
                                v-for="(bonusPointType, bonusPointTypeIndex) in bonusPointTypes"
                                :key="bonusPointTypeIndex"
                                :value="bonusPointType.name">
                                {{ bonusPointType.display }}
                            </option>

                            <!-- Movement variation -->
                            <option v-if="points.configs[index].bonus_point_type === 'movement_variation'"
                                    v-for="(movementVariation, movementVariationIndex) in movementVariations"
                                    :key="movementVariationIndex"
                                    :value="movementVariation.name">
                                {{ movementVariation.display }}
                            </option>
                        </select>
                    </div>

                    <div class="px-2 mb-5 md:mb-0">
                        <label for="operator"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Operator
                        </label>

                        <select name="operator" :disabled="shouldDisableOperatorOption(index)"
                                v-model="points.configs[index].operator"
                                class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                                id="operator">
                            <option value="null" disabled selected>Please Select...</option>
                            <option v-for="(operator, index) in operators" :key="index" :value="operator">
                                {{ operator }}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Variation loops -->
                <div v-if="points.configs[index].bonus_point_type === 'movement_variation'">
                    <label class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Variations
                    </label>

                    <div v-for="(variation, variationIndex) in points.configs[index].variations" :key="variationIndex"
                         class="my-5 grid grid-cols-1 md:grid-cols-4"
                    >
                        <div class="px-2 mb-5 md:mb-0 col-span-2">
                            <label :for="'variation_' + variationIndex + '_target'"
                                   class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                                Variation
                            </label>

                            <input :id="'variation_' + variationIndex + '_variation'" type="text"
                                   :name="'variation_' + variationIndex + '_variation'"
                                   required="required"
                                   placeholder="Variation"
                                   v-model="points.configs[index].variations[variationIndex].variation"
                                   class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                        </div>

                        <div class="px-2 mb-5 md:mb-0">
                            <label :for="'variation_' + variationIndex + '_points'"
                                   class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                                Points
                            </label>

                            <input :id="'variation_' + variationIndex + '_points'" type="number"
                                   :name="'variation_' + variationIndex + '_points'"
                                   required="required" placeholder="Points"
                                   v-model="points.configs[index].variations[variationIndex].points"
                                   class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                        </div>

                        <div class="px-2 mb-5 md:mb-0">
                            <button class="mt-10 mr-5" @click="addVariation(index)">
                                <i class="far fa-plus"></i>
                            </button>

                            <button v-if="points.configs[index].variations.length > 1"
                                    @click="deleteVariation(index, variationIndex)">
                                <i class="far fa-minus"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Tiered Points -->
                <div v-if="points.configs[index].point_type === 'tiered'">
                    <div class="my-5 grid grid-cols-1 md:grid-cols-3">
                        <div class="px-2 mb-5 md:mb-0">
                            <label for="number_of_tiers"
                                   class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                                Number of Tiers
                            </label>

                            <input id="number_of_tiers" type="number" name="number_of_tiers" required="required"
                                   placeholder="Number of Tiers"
                                   v-model="points.configs[index].number_of_tiers"
                                   class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                        </div>

                        <div class="px-2 mb-5 md:mb-0">
                            <label for="top_target"
                                   class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                                Top Target
                            </label>

                            <input id="top_target" :type="getTargetType(index)" name="top_target" required="required"
                                   placeholder="Top Target" step="1"
                                   v-model="points.configs[index].top_target"
                                   class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                        </div>

                        <div class="px-2 mb-5 md:mb-0">
                            <label for="bottom_target"
                                   class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                                Bottom Target
                            </label>

                            <input id="bottom_target" :type="getTargetType(index)" name="bottom_target"
                                   required="required"
                                   placeholder="Bottom Target" step="1"
                                   v-model="points.configs[index].bottom_target"
                                   class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                        </div>
                    </div>

                    <div v-if="points.configs[index].tiers.length">
                        <div class="grid grid-cols-2">
                            <p class="text-black font-bold mb-2">
                                Tiers
                            </p>
                        </div>

                        <div v-for="(tier, tierIndex) in points.configs[index].tiers" :key="tierIndex"
                             class="my-5 grid grid-cols-1 md:grid-cols-2">
                            <div class="px-2 mb-5 md:mb-0">
                                <label :for="'tier_' + tierIndex + '_target'"
                                       class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                                    Target
                                </label>

                                <input :id="'tier_' + tierIndex + '_target'" :type="getTargetType(index)" step="1"
                                       :name="'tier_' + tierIndex + '_target'"
                                       required="required"
                                       placeholder="Target"
                                       v-model="points.configs[index].tiers[tierIndex].target"
                                       class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                            </div>

                            <div class="px-2 mb-5 md:mb-0">
                                <label :for="'tier_' + tierIndex + '_points'"
                                       class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                                    Points
                                </label>

                                <input :id="'tier_' + tierIndex + '_points'" type="number"
                                       :name="'tier_' + tierIndex + '_points'"
                                       required="required" placeholder="Points"
                                       v-model="points.configs[index].tiers[tierIndex].points"
                                       class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                            </div>
                        </div>
                    </div>

                    <div class="my-5 grid grid-cols-1">
                        <div class="px-2 mb-5 md:mb-0 flex justify-center">
                            <button
                                class="mb-5 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                                @click="generateTiers(index)"
                            >
                                {{ points.configs[index].tiers_generated ? 'Regenerate' : 'Generate' }}
                            </button>
                        </div>
                    </div>
                </div>

                <hr v-if="points.configs.length > 1">
            </div>

            <div class="my-5 grid grid-cols-1">
                <div class="px-2 mb-5 md:mb-0 flex justify-end">
                    <button
                        class="mb-5 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                        @click="addNewPointConfig()"
                    >
                        Add Config
                    </button>

                    <button
                        v-if="points.configs.length > 1"
                        class="ml-5 mb-5 inline-flex items-center px-4 py-2 bg-grey rounded-md font-semibold text-xs text-black uppercase tracking-widest hover:bg-black hover:text-white active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                        @click="deletePointConfig()"
                    >
                        Delete Config
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SuccessMessage from "../includes/success-message";
import ErrorMessage from "../includes/error-message";

export default {
    name: "bonus-points",

    components: {
        SuccessMessage,
        ErrorMessage,
    },

    props: {
        pointsProp: {
            type: Object,
            required: true,
            default: {
                'active': false,
                'configs': {
                    'bonus_point_type': null,
                    'max_points': null,
                    'point_type': 'single',
                    'target': null,
                    'operator': null,
                    'number_of_tiers': null,
                    'top_target': null,
                    'bottom_target': null,
                    'tiers': [],
                    'variations': [
                        {'variation': '', 'points': null}
                    ],
                    'tiers_generated': false,
                    'entry_level': 0,
                }
            },
        },
    },

    data() {
        return {
            successMessage: null,
            errors: [],

            points: this.pointsProp,

            bonusPointTypes: [
                {'name': 'time', 'display': 'Time'},
                {'name': 'distance', 'display': 'Distance'},
                {'name': 'elevation', 'display': 'Elevation'},
                {'name': 'repetitions', 'display': 'Repetitions'},
                {'name': 'completed_rounds', 'display': 'Completed Rounds'},
                {'name': 'time_of_day', 'display': 'Time of Day'},
                {'name': 'number_of_submissions', 'display': 'Number of Submissions'},
                {'name': 'beat_previous_submission', 'display': 'Beat Previous Submission'},
                {'name': 'movement_variation', 'display': 'Movement Variation'},
            ],

            movementVariations: [
                {'name': 'push_ups', 'display': 'Push-ups'},
                {'name': 'burpees', 'display': 'Burpees'},
                {'name': 'sit_ups', 'display': 'Sit-ups'},
                {'name': 'load_bearing', 'display': 'Load Bearing'},
                {'name': 'pull_ups', 'display': 'Pull-ups'},
                {'name': 'lunges', 'display': 'Lunges'},
                {'name': 'squats', 'display': 'Squats'},
                {'name': 'jumping_squats', 'display': 'Jumping Squats'},
                {'name': 'plank', 'display': 'Plank'},
                {'name': 'wall_sit', 'display': 'Wall Sit'},
                {'name': 'handstand_hold', 'display': 'Handstand Hold'},
                {'name': 'handstand_push_ups', 'display': 'Handstand Push-ups'},
                {'name': 'running_partner', 'display': 'Running Partner'},
            ],

            entryLevels: [
                {'id': 0, 'display': 'All'},
                {'id': 1, 'display': 'Advanced'},
                {'id': 2, 'display': 'Intermediate'},
                {'id': 3, 'display': 'Moderate'},
                {'id': 4, 'display': 'Novice'},
            ],

            operators: [
                '<',
                '<=',
                '>',
                '>=',
            ],
        };
    },

    methods: {
        /**
         * Show target select options rather than free text input
         * @param index
         * @returns {boolean}
         */
        showTargetSelect(index) {
            const typeOptionArray = ['beat_previous_submission', 'movement_variation'];
            return typeOptionArray.includes(this.points.configs[index].bonus_point_type);
        },

        /**
         * Decide if we should disable tiered bonus point option
         * @param index
         * @returns {boolean}
         */
        shouldDisableTieredOption(index) {
            const typeOptionArray = ['beat_previous_submission', 'movement_variation'];
            return typeOptionArray.includes(this.points.configs[index].bonus_point_type);
        },

        /**
         * Decide if we should disable entry level option
         * @param index
         * @returns {boolean}
         */
        shouldDisableEntryLevelOption(index) {
            const typeOptionArray = ['movement_variation'];
            return typeOptionArray.includes(this.points.configs[index].bonus_point_type);
        },

        /**
         * Decide if we should disable max points option
         * @param index
         * @returns {boolean}
         */
        shouldDisableMaxPointsOption(index) {
            const typeOptionArray = ['movement_variation'];
            return typeOptionArray.includes(this.points.configs[index].bonus_point_type);
        },

        /**
         * Decide if we should disable target option
         * @param index
         * @returns {boolean}
         */
        shouldDisableTargetOption(index) {
            const typeOptionArray = [];
            return typeOptionArray.includes(this.points.configs[index].bonus_point_type);
        },

        /**
         * Decide if we should disable operator option
         * @param index
         * @returns {boolean}
         */
        shouldDisableOperatorOption(index) {
            const typeOptionArray = ['movement_variation'];
            return typeOptionArray.includes(this.points.configs[index].bonus_point_type);
        },

        /**
         * Get the target type based on bonus point type
         * @param index
         * @returns {string}
         */
        getTargetType(index) {
            switch (this.points.configs[index].bonus_point_type) {
                case 'time':
                case 'time_of_day':
                    return 'time';
                default:
                    return 'number';
            }
        },

        /**
         * Push new bonus point config to points array
         */
        addNewPointConfig() {
            this.points.configs.push({
                'bonus_point_type': null,
                'max_points': null,
                'point_type': 'single',
                'target': null,
                'operator': null,
                'number_of_tiers': null,
                'top_target': null,
                'bottom_target': null,
                'tiers': [],
                'variations': [
                    {'variation': '', 'points': null}
                ],
                'tiers_generated': false,
                'entry_level': 0,
            });
        },

        /**
         * Deletes a point config from the points array
         */
        deletePointConfig(index = null) {
            if (index) {
                this.points.configs = this.points.configs.splice(index, 1);
            } else {
                this.points.configs.pop();
            }
        },

        /**
         * Add a new variation to the array
         * @param index
         */
        addVariation(index) {
            this.points.configs[index].variations.push({
                'variation': '',
                'points': null
            });
        },

        /**
         * Deletes a variation from the variations array
         */
        deleteVariation(index, variationIndex = null) {
            if (variationIndex) {
                this.points.configs[index].variations = this.points.configs[index].variations.splice(variationIndex, 1);
            } else {
                this.points.configs[index].variations.pop();
            }
        },

        /**
         * @returns {boolean}
         */
        validateInformation(index) {
            // Validate input
            return !(this.points.configs[index].max_points === null ||
                this.points.configs[index].number_of_tiers === null ||
                this.points.configs[index].top_target === null ||
                this.points.configs[index].bottom_target === null);
        },

        /**
         * Generate tiers based on the information inputted by the user
         * @param index
         */
        generateTiers(index) {
            this.errors = [];
            if (!this.validateInformation(index)) {
                this.errors = [
                    'Please make sure all information is present to generate tiers.'
                ]
                return;
            }

            let tiers = [];
            let points = this.points.configs[index].max_points / this.points.configs[index].number_of_tiers;

            tiers.push({
                'target': this.points.configs[index].top_target,
                'points': this.points.configs[index].max_points ?? null,
            });

            for (let i = 1; i <= (this.points.configs[index].number_of_tiers - 2); i++) {
                tiers.push({
                    'target': this.getTarget(this.points.configs[index], i),
                    'points': this.points.configs[index].max_points - (points.toFixed(0) * i),
                });
            }

            tiers.push({
                'target': this.points.configs[index].bottom_target,
                'points': points,
            });

            this.points.configs[index].tiers = tiers;
            this.points.configs[index].tiers_generated = true;
        },

        /**
         * @param pointConfig
         * @param i
         * @returns {string|number}
         */
        getTarget(pointConfig, i) {
            if (pointConfig.bonus_point_type == 'time') {
                const topTarget = pointConfig.top_target.split(":");
                const bottomTarget = pointConfig.bottom_target.split(":");

                const date1 = new Date();
                date1.setHours(topTarget[0], topTarget[1], topTarget[2]);

                const date2 = new Date();
                date2.setHours(bottomTarget[0], bottomTarget[1], bottomTarget[2]);

                const diffInMs = Math.abs(date2 - date1) / 1000;
                date1.setSeconds(date1.getSeconds() + ((diffInMs / pointConfig.number_of_tiers) * i));

                return date1.toTimeString().split(' ')[0];
            }

            let target = pointConfig.top_target / pointConfig.number_of_tiers;
            return pointConfig.top_target - (target.toFixed(0) * i);
        },
    },

    watch: {}
}
</script>

<style scoped>

</style>
