<template>
    <div v-if="showLoading" class="loader"></div>
</template>

<script>
export default {
    name: "loader",

    props: {
        showLoadingProp: {
            type: Boolean,
            required: true,
        }
    },

    computed: {
        /**
         * @returns {boolean}
         */
        showLoading() {
            return this.showLoadingProp;
        }
    }
}
</script>

<style scoped>
.loader {
    border: 10px solid #dadad9;
    border-top: 10px solid #a4e704;
    border-bottom: 10px solid #a4e704;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
