<template>
    <div>
        <success-message :success-message-prop="successMessage"></success-message>
        <error-message :errors-prop="errors"></error-message>

        <div v-if="points.active">
            <div class="my-5 grid grid-cols-1 md:grid-cols-2">
                <div class="px-2 mb-5 md:mb-0">
                    <label for="max_points"
                           class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Max Points
                    </label>

                    <input id="max_points" type="number" name="max_points" required="required" placeholder="Max Points"
                           v-model="points.max_points"
                           class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                </div>

                <div class="px-2 mb-5 md:mb-0">
                    <label for="point_type"
                           class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Point Type
                    </label>

                    <select name="point_type"
                            v-model="points.point_type"
                            class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                            id="point_type">
                        <option value="null" disabled selected>Please Select...</option>
                        <option value="single" selected>Single</option>
                        <option value="tiered">Tiered</option>
                    </select>
                </div>
            </div>

            <!-- Single Points-->
            <div v-if="points.point_type === 'single'" class="my-5 grid grid-cols-1 md:grid-cols-2">
                <div class="px-2 mb-5 md:mb-0">
                    <label for="target" class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Target
                    </label>

                    <input id="target" :type="targetType" name="target" required="required" placeholder="Target"
                           v-model="points.target" step="1"
                           class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                </div>

                <div class="px-2 mb-5 md:mb-0">
                    <label for="operator" class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                        Operator
                    </label>

                    <select name="operator"
                            v-model="points.operator"
                            class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                            id="operator">
                        <option value="null" disabled selected>Please Select...</option>
                        <option v-for="(operator, index) in operators" :key="index" :value="operator">{{ operator }}
                        </option>
                    </select>
                </div>
            </div>

            <!-- Tiered Points -->
            <div v-if="points.point_type === 'tiered'">
                <div class="my-5 grid grid-cols-1 md:grid-cols-3">
                    <div class="px-2 mb-5 md:mb-0">
                        <label for="number_of_tiers"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Number of Tiers
                        </label>

                        <input id="number_of_tiers" type="number" name="number_of_tiers" required="required"
                               placeholder="Number of Tiers"
                               v-model="points.number_of_tiers"
                               class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                    </div>

                    <div class="px-2 mb-5 md:mb-0">
                        <label for="top_target"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Top Target
                        </label>

                        <input id="top_target" :type="targetType" name="top_target" required="required"
                               placeholder="Top Target" step="1"
                               v-model="points.top_target"
                               class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                    </div>

                    <div class="px-2 mb-5 md:mb-0">
                        <label for="bottom_target"
                               class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                            Bottom Target
                        </label>

                        <input id="bottom_target" :type="targetType" name="bottom_target" required="required"
                               placeholder="Bottom Target" step="1"
                               v-model="points.bottom_target"
                               class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                    </div>
                </div>

                <div v-if="points.tiers.length">
                    <div class="grid grid-cols-2">
                        <p class="text-black font-bold mb-2">
                            Tiers
                        </p>
                    </div>

                    <div v-for="(tier, index) in points.tiers" :key="index"
                         class="my-5 grid grid-cols-1 md:grid-cols-2">
                        <div class="px-2 mb-5 md:mb-0">
                            <label :for="'tier_' + index + '_target'"
                                   class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                                Target
                            </label>

                            <input :id="'tier_' + index + '_target'" :type="targetType"
                                   :name="'tier_' + index + '_target'"
                                   required="required" step="1"
                                   placeholder="Target"
                                   v-model="points.tiers[index].target"
                                   class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                        </div>

                        <div class="px-2 mb-5 md:mb-0">
                            <label :for="'tier_' + index + '_points'"
                                   class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                                Points
                            </label>

                            <input :id="'tier_' + index + '_points'" type="number" :name="'tier_' + index + '_points'"
                                   required="required" placeholder="Points"
                                   v-model="points.tiers[index].points"
                                   class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
                        </div>
                    </div>
                </div>

                <div class="my-5 grid grid-cols-1">
                    <div class="px-2 mb-5 md:mb-0 flex justify-center">
                        <button
                            class="mb-5 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                            @click="generateTiers()"
                        >
                            {{ tiersGenerated ? 'Regenerate' : 'Generate' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SuccessMessage from "../includes/success-message";
import ErrorMessage from "../includes/error-message";

export default {
    name: "challenge-points",

    components: {
        SuccessMessage,
        ErrorMessage,
    },

    props: {
        pointsProp: {
            type: Object,
            required: true,
            default: {
                'max_points': null,
                'point_type': 'single',
                'target': null,
                'operator': null,
                'number_of_tiers': null,
                'top_target': null,
                'bottom_target': null,
                'tiers': [],
            },
        },
        challengeTypeProp: {
            type: String,
            required: false,
            default: '',
        }
    },

    data() {
        return {
            successMessage: null,
            errors: [],

            points: this.pointsProp,
            challengeType: this.challengeTypeProp,

            operators: [
                '<',
                '<=',
                '>',
                '>=',
            ],

            tiersGenerated: false,
        };
    },

    methods: {
        /**
         * @returns {boolean}
         */
        validateInformation() {
            // Validate input
            return !(this.points.max_points === null ||
                this.points.number_of_tiers === null ||
                this.points.top_target === null ||
                this.points.bottom_target === null);
        },

        /**
         * Generate tiers based on the information inputted by the user
         */
        generateTiers() {
            this.errors = [];
            if (!this.validateInformation()) {
                this.errors = [
                    'Please make sure all information is present to generate tiers.'
                ]
                return;
            }

            let tiers = [];
            let points = this.points.max_points / this.points.number_of_tiers;

            tiers.push({
                'target': this.points.top_target,
                'points': this.points.max_points ?? null,
            });

            for (let i = 1; i <= (this.points.number_of_tiers - 2); i++) {
                tiers.push({
                    'target': this.getTarget(this.points, i),
                    'points': this.points.max_points - (points.toFixed(0) * i),
                });
            }

            tiers.push({
                'target': this.points.bottom_target,
                'points': points,
            });

            this.points.tiers = tiers;
            this.tiersGenerated = true;
        },

        /**
         * @param pointConfig
         * @param i
         * @returns {string|number}
         */
        getTarget(pointConfig, i) {
            if (this.challengeType == 'time') {
                const topTarget = pointConfig.top_target.split(":");
                const bottomTarget = pointConfig.bottom_target.split(":");

                const date1 = new Date();
                date1.setHours(topTarget[0], topTarget[1], topTarget[2]);

                const date2 = new Date();
                date2.setHours(bottomTarget[0], bottomTarget[1], bottomTarget[2]);

                const diffInMs = Math.abs(date2 - date1) / 1000;
                date1.setSeconds(date1.getSeconds() + ((diffInMs / pointConfig.number_of_tiers) * i));

                return date1.toTimeString().split(' ')[0];
            }

            let target = pointConfig.top_target / pointConfig.number_of_tiers;
            return pointConfig.top_target - (target.toFixed(0) * i);
        },
    },

    watch: {},

    computed: {
        /**
         * @returns {string}
         */
        targetType() {
            switch (this.challengeType) {
                case 'time':
                    return 'time';
                default:
                    return 'number';
            }
        }
    }
}
</script>

<style scoped>

</style>
