<template>
    <div v-if="successMessage" class="flex flex-col sm:justify-center items-center mt-6">
        <div role="alert" class="max-w-4xl w-full">
            <div class="bg-gold text-white font-bold rounded-t px-4 py-2">
                Success
            </div>
            <div class="border border-t-0 border-gold rounded-b bg-white px-4 py-3 text-gold">
                <p>{{ successMessage }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "success-message",

    props: {
        successMessageProp: {
            type: String,
            required: false,
            default: null,
        }
    },

    data() {
        return {}
    },

    computed: {
        successMessage() {
            return this.successMessageProp;
        }
    },

    watch: {
        /**
         * Watch and clear success Message after 5 seconds
         */
        successMessage() {
            const self = this;
            if (self.successMessage !== null) {
                setTimeout(function () {
                    self.successMessage = null;
                }, 5000);
            }
        }
    }
}
</script>

<style scoped>

</style>
