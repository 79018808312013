<template>
    <div class="py-10 mx-auto">
        <!-- Year Selection -->
        <div class="px-2">
            <label for="challenge-selection"
                   class="text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                Year Selection
            </label>

            <span v-if="showOptionsAsButtons">
                <button v-for="(year, index) in seriesYears" :key="index"
                        class="mb-5 mr-5 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                        :class="isYearActive(index) ? 'bg-gold' : ''"
                        :disabled="isLoading"
                        @click="selectYear(index)"
                >
                    {{ year }}
                </button>
            </span>

            <span v-else>
                <select
                        name="year-selection"
                        v-model="selectedYearIndex"
                        class="mb-5 rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                        id="year-selection"
                        :disabled="isLoading"
                >
                    <option value="null" disabled selected>Please Select...</option>
                    <option v-for="(year, index) in seriesYears" :key="index" :value="index">
                        {{ year }}
                    </option>
                </select>
            </span>
        </div>

        <!-- Challenge Selection -->
        <div class="px-2">
            <label for="challenge-selection"
                   class="text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                Challenge Selection
            </label>

            <div v-if="showOptionsAsButtons">
                <button v-for="(challenge, index) in seriesChallenges" :key="index"
                        class="mb-5 mr-5 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                        :class="isChallengeActive(index) ? 'bg-gold' : ''"
                        :disabled="isLoading"
                        @click="selectChallenge(index)"
                >
                    {{ challenge }}
                </button>
            </div>

            <select v-else
                    name="challenge-selection"
                    v-model="selectedChallengeIndex"
                    class="mb-5 rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                    id="challenge-selection"
                    :disabled="isLoading"
            >
                <option value="null" disabled selected>Please Select...</option>
                <option v-for="(challenge, index) in seriesChallenges" :key="index" :value="index">
                    {{ challenge }}
                </option>
            </select>
        </div>

        <!-- Search -->
        <div class="my-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <div class="px-2 mb-5 lg:mb-0 col-span-2">
                <label for="search-input"
                       class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                    Search
                </label>

                <input id="search-input" type="text" name="search" placeholder="Search Records (Name/ Order Number)"
                       v-model="searchInput" :disabled="isLoading"
                       class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full">
            </div>

            <div class="px-2 mb-5 lg:mb-0">
                <label for="order-by"
                       class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                    Order By
                </label>

                <select name="order-by"
                        v-model="orderBy"
                        class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                        id="order-by"
                        :disabled="isLoading"
                >
                    <option value="null" disabled selected>Please Select...</option>
                    <option v-for="(orderOption, index) in orderOptions" :key="index" :value="orderOption.id">
                        {{ orderOption.name }}
                    </option>
                </select>
            </div>

            <div class="px-2 mb-5 lg:mb-0">
                <label for="order-direction"
                       class="font-medium text-gray-700 block text-gray-700 text-sm font-bold mb-2">
                    Order Direction
                </label>

                <select name="order-direction"
                        v-model="orderDir"
                        class="rounded-md shadow-sm border-gray focus:border-black focus:border-opacity-50 focus:outline-none focus:ring-0 focus:ring-opacity-0 disabled: disabled:opacity-50 w-full"
                        id="order-direction"
                        :disabled="isLoading"
                >
                    <option value="null" disabled selected>Please Select...</option>
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                </select>
            </div>
        </div>

        <!-- Loader -->
        <div v-if="isLoading" class="py-8 flex justify-center items-center">
            <loader :show-loading-prop="isLoading"></loader>
        </div>

        <button v-if="(adminView && isAdminUser) && !isLoading"
                class="hover:pointer btn-link float-right mr-5"
                @click="exportData()"
        >
            Export <font-awesome-icon icon="fa-regular fa-download"/>
        </button>

        <!-- Table -->
        <div v-if="!isLoading" class="py-8 px-2">
            <div class="bg-white overflow-x-auto">
                <table class="w-full mx-auto whitespace-nowrap bg-white">
                    <thead class="justify-between">
                    <tr class="bg-black text-white text-left">
                        <th v-for="(column, index) in leaderboard.columns" :key="index" class="px-2 py-3">
                            {{ column }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(entry, index) in leaderboardData" :key="index" class="bg-white border-2 border-gray">
                        <td v-for="(column, colIndex) in leaderboard.columns" :key="colIndex" class="px-2 py-3">
                            <span :class="[shouldTextBeGray(index) ? 'text-gray' : '']"
                                  v-if="shouldShowColumn(column)"
                                  v-html="formatAndDisplay(index, column)">
                            </span>

                            <input id="checked" v-if="column === 'Checked'" type="checkbox" name="checked"
                                   @click="checkClicked(index)" v-model="entry.checked"
                                   class="form-check text-gray-700 text-sm font-bold h-5 w-5" value="1"/>

                            <button v-if="column === 'Show'" class="hover:pointer btn-link mx-auto">
                                <font-awesome-icon @click="showClicked(index)"
                                                   v-if="entry.show == 0"
                                                   icon="fa-regular fa-check-circle"/>
                                <font-awesome-icon @click="showClicked(index, true)"
                                                   v-if="entry.show == 1"
                                                   icon="fa-regular fa-times-circle"/>
                            </button>

                            <a v-if="column === 'View User'"
                               target="_blank" :href="'/admin/users/view/' + entry.user_id"
                               class="hover:pointer mx-auto">
                                <font-awesome-icon icon="fa-regular fa-eye"/>
                            </a>

                            <a v-if="column === 'View Submission'"
                               target="_blank" :href="getSubmissionFilterUrl(entry)"
                               class="hover:pointer mx-auto">
                                <font-awesome-icon icon="fa-regular fa-eye"/>
                            </a>
                        </td>
                    </tr>

                    <tr v-if="!leaderboardData.length && !error.error" class="bg-white border-2 border-gray">
                        <td colspan="10" class="text-center px-4 py-6">
                            No leaderboard entries found...
                        </td>
                    </tr>

                    <tr v-if="!leaderboardData.length && error.error" class="bg-white border-2 border-gray">
                        <td colspan="10" class="text-center px-4 py-6">
                            {{ error.message }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- Page Selection -->
            <div class="px-2 flex justify-end">
                <button v-for="(page, index) in pages" :key="index"
                        class="my-5 mr-2 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                        :class="isPageActive(index) ? 'bg-gold' : ''"
                        @click="selectPage(index)"
                >
                    {{ page }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "./includes/loader";
import viewEvidence from "./admin/leaderboard/view-evidence.vue";
import actions from "./admin/leaderboard/actions.vue";

export default {
    name: "leaderboard",

    components: {
        Loader,
        viewEvidence,
        actions
    },

    props: {
        isAdminUser: {
            type: Boolean,
            required: false,
            default() {
                return false
            }
        },
        adminView: {
            type: Boolean,
            required: false,
            default() {
                return false
            }
        },
        seriesNameProp: {
            type: String,
            required: true,
        },
        seriesSlugProp: {
            type: String,
            required: true,
        },
        seriesIdProp: {
            type: Number,
            required: true,
        },
        seriesYearProp: {
            type: Array,
            required: false,
            default() {
                return [new Date().getFullYear()]
            }
        },
        seriesChallengesProp: {
            type: Array,
            required: true,
            default: []
        },
        selectedChallenge: {
            required: false,
            default: null
        }
    },

    data() {
        return {
            seriesName: this.seriesNameProp,
            seriesId: this.seriesIdProp,
            seriesSlug: this.seriesSlugProp,
            seriesYears: this.seriesYearProp,
            seriesChallenges: this.seriesChallengesProp,

            isLoading: true,
            windowWidth: window.innerWidth,

            error: {
                error: false,
                message: null
            },

            selectedYearIndex: 0,
            selectedChallengeIndex: 0,

            selectedPage: 1,
            searchInput: null,
            orderBy: 'pos',
            orderDir: 'asc',

            leaderboard: {
                columns: [
                    'Pos',
                    'Name',
                    'Level',
                    'Gender',
                    'Age Category',
                    'Location',
                    'Points',
                ],
                data: {},
            },

            searchResults: {},

            rowsPerPage: (this.adminView && this.isAdminUser) ? 1000 : 50,
        }
    },

    computed: {
        /**
         * Return order by options based on admin view or visitor view
         * @returns {[{name: string, id: string},{name: string, id: string},{name: string, id: string},{name: string, id: string},{name: string, id: string}]|[{name: string, id: string},{name: string, id: string},{name: string, id: string},{name: string, id: string},{name: string, id: string},null]}
         */
        orderOptions() {
            if (this.adminView) {
                return [
                    {
                        'id': 'pos',
                        'name': 'Pos'
                    },
                    {
                        'id': 'name',
                        'name': 'Name'
                    },
                    {
                        'id': 'level',
                        'name': 'Entry Level'
                    },
                    {
                        'id': 'checked',
                        'name': 'Checked'
                    },
                    {
                        'id': 'show',
                        'name': 'Show'
                    },
                    {
                        'id': 'count',
                        'name': 'Count'
                    }
                ];
            }

            return [
                {
                    'id': 'pos',
                    'name': 'Pos'
                },
                {
                    'id': 'name',
                    'name': 'Name'
                },
                {
                    'id': 'level',
                    'name': 'Entry Level'
                },
                {
                    'id': 'gender',
                    'name': 'Gender'
                },
                {
                    'id': 'age_category',
                    'name': 'Age Group'
                },
                {
                    'id': 'location',
                    'name': 'Location'
                }
            ];
        },

        /**
         * @returns {{length}|{}|{}}
         */
        leaderboardData() {
            // Get data
            let selectedData = this.leaderboard.data;
            if (
                this.searchResults.length ||
                (this.searchInput !== null && this.searchInput.length >= 3)
            ) {
                selectedData = this.searchResults;
            }

            // Load according to page number
            let pageRecords = [];
            let startingAt = (this.rowsPerPage * this.selectedPage) - this.rowsPerPage;
            let finishingAt = (this.rowsPerPage * this.selectedPage);
            for (let i = startingAt; i < finishingAt; i++) {
                if (selectedData[i] !== undefined) {
                    pageRecords.push(selectedData[i]);
                }
            }

            return pageRecords;
        },

        /**
         * Works out the number of pages to display
         * @returns {*[]}
         */
        pages() {
            let numberOfRecords = this.leaderboard.data.length;
            let numberOfPages = (numberOfRecords < this.rowsPerPage) ? 1 : Math.round(numberOfRecords / this.rowsPerPage) + 1;

            let pageNumbers = [];
            for (let i = 1; i <= numberOfPages; i++) {
                pageNumbers.push(i);
            }

            return pageNumbers;
        },

        /**
         * Work out if we show buttons or select options
         * @returns {boolean}
         */
        showOptionsAsButtons() {
            return !(this.windowWidth <= 700);
        }
    },

    methods: {
        /**
         * @param index
         */
        selectYear(index) {
            this.selectedYearIndex = [index];
        },

        /**
         * @param index
         * @returns {boolean}
         */
        isYearActive(index) {
            return index == this.selectedYearIndex;
        },

        /**
         * @param index
         */
        selectChallenge(index) {
            this.selectedChallengeIndex = [index];
        },

        /**
         * @param index
         * @returns {boolean}
         */
        isChallengeActive(index) {
            return index == this.selectedChallengeIndex;
        },

        /**
         * @param index
         */
        selectPage(index) {
            this.selectedPage = this.pages[index];
        },

        /**
         * @param index
         * @returns {boolean}
         */
        isPageActive(index) {
            return this.pages[index] === this.selectedPage;
        },

        loadChallengesForYears() {
            const self = this;
            const url = `/leaderboard/load/${this.seriesId}/${this.seriesSlug}/${this.seriesYears[this.selectedYearIndex]}/challenges`;
            axios.get(url)
                .then((response) => {
                    self.seriesChallenges = response.data.challenges;
                })
        },

        /**
         * Load the leaderboard
         */
        loadLeaderboard() {
            const self = this;
            self.isLoading = true;
            self.error = {
                error: false,
                message: null
            }

            const challenge = encodeURIComponent(this.seriesChallenges[this.selectedChallengeIndex].toLowerCase());
            const params = `order_by=${this.orderBy}&order_dir=${this.orderDir}&challenge=${challenge}`;

            this.loadChallengesForYears();

            // Url
            let url = `/load/${this.seriesId}/${this.seriesSlug}/${this.seriesYears[this.selectedYearIndex]}?${params}`;
            url = (this.isAdminUser && this.adminView) ? '/admin-leaderboard' + url : '/leaderboard' + url;

            axios.get(url)
                .then((response) => {
                    self.selectedPage = 1;
                    self.leaderboard.columns = response.data.columns;
                    self.leaderboard.data = response.data.data;
                    self.isLoading = false;
                })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }

                    // @TODO: Show error message
                    self.leaderboard.data = []
                    self.isLoading = false;
                    self.error = {
                        error: true,
                        message: 'Sorry there seems to have been an error trying to load the leaderboard. Please try again or contact Rokman support.'
                    }
                });
        },

        /**
         * Export data to csv
         */
        exportData() {
            // FE Check
            if (!this.isAdminUser && !this.adminView) {
                return;
            }

            const challenge = encodeURIComponent(this.seriesChallenges[this.selectedChallengeIndex].toLowerCase());
            const params = `order_by=${this.orderBy}&order_dir=${this.orderDir}&challenge=${challenge}`;

            const url = `/admin-leaderboard/download/${this.seriesId}/${this.seriesSlug}/${this.seriesYears[this.selectedYearIndex]}?${params}`;
            window.location.assign(url);
        },

        /**
         * Use the search input to search via name or order number
         */
        searchLeaderboard() {
            // Exit early if search input is less than three chars
            if (this.searchInput.length < 3) {
                this.searchResults = {};
                return;
            }

            const self = this;
            const searchResults = [];
            Object.values(this.leaderboard.data).forEach(entry => {
                if (
                    entry.name.toLowerCase().includes(self.searchInput.toLowerCase()) ||
                    entry.order_number.toString().includes(self.searchInput.toLowerCase())
                ) {
                    searchResults.push(entry);
                }
            });

            this.searchResults = searchResults;
        },

        /**
         * On Resize of winder set inner width
         */
        onResize() {
            this.windowWidth = window.innerWidth;
        },

        /**
         * Format and display data based on col type/name
         * @param entryIndex
         * @param column
         * @returns {*}
         */
        formatAndDisplay(entryIndex, column) {
            const columnName = column.toLowerCase().replace(/ /g, "_").replace(/,/g, "");
            const entry = this.leaderboardData[entryIndex];

            // if not admin view -> return early
            if (!this.adminView) {
                return entry[columnName];
            }

            // Email -> wrap in anchor tag
            if (columnName === 'email') {
                return `<a class="text-gold underline hover:pointer" href="mailto:${entry.email}">${entry.email}</a>`;
            }

            // Check count and if it matches the number of series challenges then output bold and gold
            if (columnName === 'count') {
                if (entry.count === (this.seriesChallenges.length - 1)) {
                    return `<span class="font-bold text-gold">${entry.count}</span>`;
                }

                return entry.count;
            }

            return entry[columnName];
        },

        /**
         * Check if font should be gray for entry
         * @param entryIndex
         * @returns {boolean}
         */
        shouldTextBeGray(entryIndex) {
            if (!this.adminView) {
                return false;
            }

            return (this.leaderboard.data[entryIndex]?.show === 1);
        },

        /**
         * When checked we want to change the value locally and in the db
         * @param entryIndex
         */
        checkClicked(entryIndex) {
            this.leaderboard.data[entryIndex].checked = !this.leaderboardData[entryIndex].checked;

            axios.post(`/admin-leaderboard/submission-update/checked`, {
                'submission_id': this.leaderboard.data[entryIndex].submission_id,
                'checked': this.leaderboard.data[entryIndex].checked
            });
        },

        /**
         * When clicked we want to change the value locally and in the db
         * @param entryIndex
         * @param reset
         */
        showClicked(entryIndex, reset = false) {
            this.leaderboard.data[entryIndex].show = (reset) ? 0 : 1;

            axios.post(`/admin-leaderboard/submission-update/show`, {
                'submission_id': this.leaderboard.data[entryIndex].submission_id,
                'show': (reset) ? 0 : 1
            });
        },

        /**
         * Check if column should be shown in default span class
         * @param column
         * @returns {boolean}
         */
        shouldShowColumn(column) {
            return !['Show', 'Checked', 'Evidence', 'View User', 'View Submission'].includes(column);
        },

        /**
         *
         * @param entry
         * @returns {string}
         */
        getSubmissionFilterUrl(entry) {
            return `/admin/submissions?filters=1&user=${entry.email}&challenge_series=${entry.series_id}&challenge=${entry.challenge_id}`;
        }
    },

    watch: {
        /**
         * Handle change of year
         */
        selectedYearIndex() {
            this.orderBy = 'pos';
            this.orderDir = 'asc';
            this.loadLeaderboard();
        },

        /**
         * Handle change of challenge
         */
        selectedChallengeIndex() {
            this.orderBy = 'pos';
            this.orderDir = 'asc';
            this.loadLeaderboard();
        },

        /**
         * Handle change of order by
         */
        orderBy() {
            this.loadLeaderboard();
        },

        /**
         * Handle change of order direction
         */
        orderDir() {
            this.loadLeaderboard();
        },

        /**
         * On change of search input start to filter results
         */
        searchInput() {
            this.searchLeaderboard();
        },
    },

    mounted() {
        // If we have a selected challenge index
        if (this.selectedChallenge !== null) {
            this.selectedChallengeIndex = this.selectedChallenge;
        }

        // Load leaderboard data
        this.loadLeaderboard();

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
}
</script>

<style scoped>

</style>
