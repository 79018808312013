import { render, staticRenderFns } from "./challenge-points.vue?vue&type=template&id=82859304&scoped=true"
import script from "./challenge-points.vue?vue&type=script&lang=js"
export * from "./challenge-points.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82859304",
  null
  
)

export default component.exports