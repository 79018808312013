<template>
    <section class="points max-w-90">

        <success-message :success-message-prop="successMessage"></success-message>
        <error-message :errors-prop="errors"></error-message>

        <!-- Entry Levels -->
        <div class="max-w-full bg-white shadow-lg rounded px-4 md:px-8 mt-6 pt-6 pb-8 mb-4">
            <div class="grid grid-cols-2">
                <h3 class="block text-black font-bold mb-2 text-left uppercase tracking-wider">
                    Entry Levels
                </h3>

                <div class="flex justify-end">
                    <toggle-switch @switch-toggled="customEntryLevels = !customEntryLevels" toggle-name-prop="entryLevels"
                                   :toggle-active-prop="customEntryLevels"></toggle-switch>
                </div>
            </div>

            <p class="py-2 mb-5 md:mb-0 text-sm">
                Customise the entry levels shown for this individual challenge.
            </p>

            <entry-levels :custom-entry-levels-prop="customEntryLevels" :entry-levels-prop="entryLevels" />
        </div>

        <div class="my-5 grid grid-cols-1">
            <div class="px-2 mb-5 md:mb-0 flex justify-center">
                <button
                    class="mb-5 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                    @click="savePoints()"
                >
                    Save
                </button>
            </div>
        </div>

        <!-- Completion Points-->
        <div class="max-w-full bg-white shadow-lg rounded px-4 md:px-8 mt-6 pt-6 pb-8 mb-4">
            <div class="grid grid-cols-2">
                <h3 class="block text-black font-bold mb-2 text-left uppercase tracking-wider">
                    Completion Points
                </h3>

                <div class="flex justify-end">
                    <toggle-switch @switch-toggled="togglePoints('completion')" toggle-name-prop="completion"
                                   :disabled-prop="disableCompletionPointsSwitch"
                                   :toggle-active-prop="completionPoints.active"></toggle-switch>
                </div>
            </div>

            <p class="py-2 mb-5 md:mb-0 text-sm">
                These are points that are awarded for completion of a challenge. Either single or
                tiered based on entry level. Can be used in conjunction with challenge points or on their own.
            </p>

            <completion-points
                :points-prop="completionPoints"
                :challenge-type-prop="challengeType"
                :custom-entry-levels-prop="customEntryLevels"
                :entry-levels-prop="entryLevels"
            />
        </div>

        <div class="my-5 grid grid-cols-1">
            <div class="px-2 mb-5 md:mb-0 flex justify-center">
                <button
                    class="mb-5 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                    @click="savePoints()"
                >
                    Save
                </button>
            </div>
        </div>

        <!-- Challenge Points -->
        <div class="max-w-full bg-white shadow-lg rounded px-4 md:px-8 mt-6 pt-6 pb-8 mb-4">
            <div class="grid grid-cols-4">
                <h3 class="block text-black font-bold mb-2 text-left uppercase tracking-wider col-span-3">
                    Challenge Points
                </h3>

                <div class="flex justify-end">
                    <toggle-switch @switch-toggled="togglePoints('challenge')" toggle-name-prop="challenge"
                                   :disabled-prop="disableChallengePointsSwitch"
                                   :toggle-active-prop="challengePoints.active"></toggle-switch>
                </div>
            </div>

            <p class="py-2 mb-5 md:mb-0 text-sm">
                These are points that are awarded for reaching a specific distance, time or elevation
                etc. Can be used in conjunction with completion points or on their own.
            </p>

            <challenge-points :points-prop="challengePoints" :challenge-type-prop="challengeType"></challenge-points>
        </div>

        <div class="my-5 grid grid-cols-1">
            <div class="px-2 mb-5 md:mb-0 flex justify-center">
                <button
                    class="mb-5 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                    @click="savePoints()"
                >
                    Save
                </button>
            </div>
        </div>

        <!-- Bonus Points -->
        <div class="max-w-full bg-white shadow-lg rounded px-4 md:px-8 mt-6 pt-6 pb-8 mb-4">
            <div class="grid grid-cols-2">
                <h3 class="block text-black font-bold mb-2 text-left uppercase tracking-wider">
                    Bonus Points
                </h3>

                <div class="flex justify-end">
                    <toggle-switch @switch-toggled="togglePoints('bonus')" toggle-name-prop="bonus"
                                   :toggle-active-prop="bonusPoints.active"></toggle-switch>
                </div>
            </div>

            <p class="py-2 mb-5 md:mb-0 text-sm">
                Bonus Points are used to provide extra points to each submission if either challenge or completion
                points have been awarded.
            </p>

            <bonus-points
                :points-prop="bonusPoints"
                :custom-entry-levels-prop="customEntryLevels"
                :entry-levels-prop="entryLevels"
            />
        </div>

        <div class="my-5 grid grid-cols-1">
            <div class="px-2 mb-5 md:mb-0 flex justify-center">
                <button
                    class="mb-5 inline-flex items-center px-4 py-2 bg-black rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray hover:text-black active:bg-gray focus:outline-none focus:border-gray ring-gray disabled:opacity-25 transition ease-in-out duration-150 cursor-pointer"
                    @click="savePoints()"
                >
                    Save
                </button>
            </div>
        </div>

    </section>
</template>

<script>
import SuccessMessage from "../includes/success-message";
import ErrorMessage from "../includes/error-message";
import ToggleSwitch from "./ToggleSwitch";
import CompletionPoints from "./completion-points";
import ChallengePoints from "./challenge-points";
import BonusPoints from "./bonus-points";
import EntryLevels from "./entry-levels.vue";

export default {
    name: "points-configuration",

    components: {
        SuccessMessage,
        ErrorMessage,
        ToggleSwitch,
        CompletionPoints,
        ChallengePoints,
        BonusPoints,
        EntryLevels
    },

    props: {
        seriesIdProp: {
            type: Number,
            required: true,
        },
        challengeIdProp: {
            type: Number,
            required: true,
        },
        customEntryLevelsProp: {
            type: Boolean,
            required: true,
            default: false,
        },
        completionPointsProp: {
            type: Object,
            required: true,
            default: {
                'active': true,
                'max_points': null,
                'point_type': 'single',
                'tiers': [],
            },
        },
        challengePointsProp: {
            type: Object,
            required: true,
            default: {
                'active': false,
                'max_points': null,
                'point_type': 'single',
                'target': null,
                'operator': null,
                'number_of_tiers': null,
                'top_target': null,
                'bottom_target': null,
                'tiers': [],
            },
        },
        bonusPointsProp: {
            type: Object,
            required: true,
            default: {
                'active': false,
                'configs': {
                    'bonus_point_type': null,
                    'max_points': null,
                    'point_type': 'single',
                    'target': null,
                    'operator': null,
                    'number_of_tiers': null,
                    'top_target': null,
                    'bottom_target': null,
                    'tiers': [],
                }
            },
        },
        challengeTypeProp: {
            type: String,
            required: false,
            default: '',
        },
        submissionTypeProp: {
            type: String,
            required: false,
            default: 'Single',
        },
        entryLevelsProp: {
            type: Array,
            required: true,
            default: [],
        }
    },

    data() {
        return {
            customEntryLevels: this.customEntryLevelsProp,
            entryLevels: this.entryLevelsProp,

            completionPoints: this.completionPointsProp,
            challengePoints: this.challengePointsProp,
            bonusPoints: this.bonusPointsProp,
            seriesId: this.seriesIdProp,
            challengeId: this.challengeIdProp,
            challengeType: this.challengeTypeProp,
            submissionType: this.submissionTypeProp.toLowerCase(),

            successMessage: null,
            errors: []
        };
    },

    computed: {
        disableCompletionPointsSwitch() {
            return (this.completionPoints.active && !this.challengePoints.active);
        },

        disableChallengePointsSwitch() {
            return (!this.completionPoints.active && this.challengePoints.active);
        }
    },

    methods: {
        savePoints() {
            const self = this;
            self.errors = [];

            axios.post(`/admin/challenge-series/series/${this.seriesId}/challenge/${this.challengeId}/point-configuration`, {
                completionPoints: this.completionPoints,
                challengePoints: this.challengePoints,
                bonusPoints: this.bonusPoints,
                customEntryLevels: this.customEntryLevels,
                entryLevels: this.entryLevels
            })
                .then(function (response) {
                    self.successMessage = 'Point configurations have been saved successfully';
                    self.customEntryLevels = response.data.customEntryLevels;
                    self.entryLevels = response.data.challenge.entryLevels;
                })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }

                    // @TODO: Handle error messages
                });
        },

        togglePoints(type) {
            switch (type) {
                case 'completion':
                    this.completionPoints.active = !this.completionPoints.active;
                    break;
                case 'challenge':
                    this.challengePoints.active = !this.challengePoints.active;
                    break;
                case 'bonus':
                    this.bonusPoints.active = !this.bonusPoints.active;
                    break;
            }
        }
    },

    watch: {
        successMessage() {
            const self = this;
            if (self.successMessage !== null) {
                setTimeout(function () {
                    self.successMessage = null;
                }, 5000);
            }
        }
    }
}
</script>
