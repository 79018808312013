<template>
    <div class="flex justify-center">
        <div class="toggle-container relative w-12 h-6 transition duration-200 ease-linear rounded-full"
             :class="[toggle ? 'bg-gold' : 'bg-red', disabled ? 'disabled' : '']"
        >
            <label :for="'toggle_' + toggleName"
                   class="absolute left-0 w-6 h-6 transition duration-100 ease-linear transform bg-grey rounded-full cursor-pointer"
                   :class="[toggle ? 'translate-x-full border-grey' : 'translate-x-0 border-green']"
            >
            </label>

            <input
                type="checkbox"
                id="toggle"
                :name="'toggle_' + toggleName"
                v-model="toggle"
                :disabled="disabled === true"
                class="w-full h-full appearance-none focus:outline-none"
            />
        </div>
        <!--        <div class="ml-3 text-sm">On</div>-->
    </div>
</template>

<script>
export default {
    name: "ToggleSwitch",

    props: {
        toggleNameProp: {
            type: String,
            required: false,
            default: '',
        },
        toggleActiveProp: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabledProp: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    data() {
        return {
            toggle: this.toggleActiveProp ?? false,
            toggleName: this.toggleNameProp ?? '',
        }
    },

    computed: {
        /**
         * @returns {boolean|boolean}
         */
        disabled() {
            return this.disabledProp ?? false;
        }
    },

    watch: {
        toggle() {
            this.$emit('switch-toggled', this.toggle);
        }
    }
}
</script>

<style scoped>

</style>
